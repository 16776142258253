@import 'src/assets/sass/variables';

.columnHeader {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-small;
  padding: $grid-medium $grid-small;
  justify-content: center;
  align-items: center;
  color: $white-color;
  font-weight: $font-weight-bold;
  font-size: $font-size-large;
  border-radius: $border-radius-base;
  background-color: $color-gray-primary;
}
