@import 'src/assets/sass/variables';

$attachButtonWidth: 180px;
$attachSplitZIndex: 5000;

.documentsAddButtonLabel {
  padding: 0 $grid-medium;
}

.attachButtonWidth {
  width: $attachButtonWidth;
}

.attachSplit {
  @extend .attachButtonWidth;
  z-index: $attachSplitZIndex;
  .menuButtonList {
    padding: 0;
  }
}

.attachButtonContainer {
  justify-self: end;
  position: relative;
}
