@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

$xtCommentsAddButtonHeight: 42px;
$xtCommentsMaxHeight: 500px;
$xtCommentsAddButtonRightOffset: 20px;

.xtComments {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  justify-items: end;
  grid-gap: $grid-base * 1.5;
  max-height: $xtCommentsMaxHeight;
  overflow-y: auto;
  @extend .xt-scrollbar-style;

  @media screen and (max-width: $bp-sm) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    padding-right: 0;
    grid-gap: $grid-base;
  }
}

.stickyWrapper {
  top: 0;
  position: sticky;
  left: auto;
  right: $xtCommentsAddButtonRightOffset;
  height: $xtCommentsAddButtonHeight;

  @media screen and (max-width: $bp-sm) {
    position: static;
    grid-row: 1;
    margin-right: $grid-small;
  }
}

.xtCommentsAddButton {
  left: auto;
  right: 0;
  height: $xtCommentsAddButtonHeight;
}

.xtCommentsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > *:not(:last-child) {
    margin-bottom: $grid-base;
  }

  @media screen and (max-width: $bp-sm) {
    padding-right: $grid-small;
    grid-row: 2;
  }
}

:global {
  #xt-comments-scrollable {
    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
  }
}
