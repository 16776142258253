@import 'src/assets/sass/variables';

$invalidPhoneContainerWidth: 390px;

.invalidPhoneContainer {
  width: $invalidPhoneContainerWidth;
  padding: $grid-base;
  background-color: $white-color;

  @media screen and (max-width: $bp-xs) {
    width: 100%;
    height: 100%;
  }
}

.mainContainer {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-gap: $grid-base;
  > p {
    overflow: hidden;
    white-space: break-spaces;
    word-break: break-word;
    margin: 0;
  }
}

.controlButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    width: 40%;
  }
}
