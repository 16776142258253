@import 'variables';

.xt-common-table-list {
  height: $xtTableHeight;
  @media screen and (max-width: $bp-xs) {
    overflow: auto;
  }
}

.table-filter-add-button {
  display: grid;
  grid-gap: $grid-small;
  grid-template-columns: auto auto;
  width: 100%;
  align-items: center;
  padding-bottom: $grid-base;
  > :first-child {
    justify-self: start;
  }
  > :last-child {
    justify-self: end;
    margin-left: auto;
  }
}
