@import 'src/assets/sass/variables';
$logoutColor: rgba(0, 0, 0, 0.5);
$logoutMinWidth: 110px;
$logoutTransition: color 0.2s;

.logout {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: $grid-small;
  min-width: $logoutMinWidth;
  padding: 0 $grid-medium;
  color: $logoutColor;
  &:hover {
    @extend .hoverLogout;
  }
  cursor: pointer;
  .logoutText {
    white-space: nowrap;
    margin: 0;
  }
}

.hoverLogout {
  color: $black-color;
  transition: $logoutTransition;
}

.logoutSidebar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $logoutColor;
  cursor: pointer;
  &:hover {
    background-color: white;
    border-radius: $border-radius-base;
  }
}
