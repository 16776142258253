@import 'src/assets/sass/variables';

$tooltipBackgroundColor: #f7f8fc;
$tooltipTransition: opacity 0.5s linear;
$tooltipTriangleSize: 12px;
$iconTooltipTopPosition: -14px;
$iconTooltipLeftPosition: -186px;
$iconTooltipTriangleTopPosition: -5px;
$iconTooltipTriangleLeftPosition: -15px;

.card {
  background-color: $white-color;
  display: grid;
  grid-gap: $grid-tiny;
  padding: $grid-small;
  width: 100%;
  border-radius: $border-radius-base;
  cursor: pointer;
  outline-color: $color-gray-primary;
}

.cardRow {
  display: grid;
  grid-gap: $grid-tiny;
  grid-template-columns: auto 1fr;
}

.cardRowWithIcon {
  display: grid;
  grid-gap: $grid-tiny;
  grid-template-columns: 1fr auto;
}

.cardIconContainer {
  position: relative;
  > div {
    opacity: 0;
    transition: $tooltipTransition;
  }

  &:hover {
    > div {
      opacity: 1;
      transition: $tooltipTransition;
    }
  }
}

.iconTooltipContainer {
  display: flex;
}

.iconTooltip {
  position: absolute;
  padding: $grid-small $grid-medium;
  left: $iconTooltipLeftPosition;
  top: $iconTooltipTopPosition;
  border-radius: $border-radius-base;
  background-color: $tooltipBackgroundColor;
}

.iconTooltipTriangle {
  position: absolute;
  width: 0;
  height: 0;
  top: $iconTooltipTriangleTopPosition;
  left: $iconTooltipTriangleLeftPosition;
  border-top: $tooltipTriangleSize solid transparent;
  border-bottom: $tooltipTriangleSize solid transparent;
  border-left: $tooltipTriangleSize solid $tooltipBackgroundColor;
}

.cardLabel {
  font-weight: $font-weight-bold;
}

.cardValue {
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
}
