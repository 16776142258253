@import 'src/assets/sass/variables';
$xtButtonHeight: 34px;
$xtButtonBorderSize: 1px;
$xtButtonBorderColor: rgba(0, 0, 0, 0.3);
$xtButtonSpinnerSize: 30px;
$xtButtonLineHeight: 16px;

:export {
  xtButtonSpinnerSize: $xtButtonSpinnerSize;
}

.xtButton {
  position: relative;
  cursor: pointer;
  height: $xtButtonHeight;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: $dark-gray;
    cursor: not-allowed;
  }
}
.primary {
  background-color: $color-primary;
  border: $xtButtonBorderSize solid $xtButtonBorderColor;
}

.xtButtonIcon {
  color: $white-color;

  &Hidden {
    visibility: hidden;
  }
}

.xtButtonLabel {
  padding: 0 $grid-base;
  font-size: $font-size-medium;
  color: $white-color;
  font-family: $font-family;
  line-height: $xtButtonLineHeight;

  &Hidden {
    visibility: hidden;
  }
}

.xtButtonSpinner {
  width: $xtButtonSpinnerSize;
  height: $xtButtonSpinnerSize;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $white-color;
}

.secondary {
  background-color: transparent;
  border: $border-grey;
  &:hover {
    border: $border-black;
    .xtButtonLabel {
      color: $black-color;
    }
  }
  &:disabled {
    background-color: $dark-gray;
    border: $xtButtonBorderSize solid $xtButtonBorderColor;
    .xtButtonLabel {
      color: $white-color;
    }
  }
  .xtButtonLabel {
    color: $dark-gray;
  }
}
