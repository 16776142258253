@import 'src/assets/sass/variables';

$sidebarWidth: 195px;
$sidebarZIndex: 300;
$sidebarOverlayZIndex: 200;
$sidebarOverlayBackground: rgba(0, 0, 0, 0.5);
$sidebarCloseIconSize: 12px;
$profileButtonPaddingLeft: 28px;

.sidebar {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebarWidth;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: $grid-base;
  z-index: $sidebarZIndex;
  background-color: $medium-gray;
  font-weight: $font-weight-semi;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);

  &Shown {
    left: 0;
  }

  &Hidden {
    left: -$sidebarWidth;
  }
}

.sidebarOptions {
  margin: 0;
  padding: 0 $grid-small;
  display: grid;
  justify-items: center;
  grid-template-columns: auto auto;
  grid-auto-rows: min-content;
  grid-gap: $grid-small * 2;
  li {
    display: block;
  }
}

.sidebarContainer {
  position: relative;
}

.sidebarOverlay {
  position: fixed;
  z-index: $sidebarOverlayZIndex;
  width: 100%;
  height: 100%;
  background-color: $sidebarOverlayBackground;
}

.profileButton {
  padding-left: $profileButtonPaddingLeft;
  justify-self: start;
}

:global {
  .MuiButtonBase-root.xtSidebarDynamicPanelCloseIcon {
    position: absolute;
    top: $grid-tiny;
    right: $grid-tiny;
  }
}

.closeIconSize {
  width: $sidebarCloseIconSize;
  height: $sidebarCloseIconSize;
}
