@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

.mainContainer {
  display: grid;
  grid-template-columns: auto minmax(0px, 1fr);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: $medium-gray;
  @extend .xt-scrollbar-style;
}

.mainPanel {
  background-color: $medium-gray;
}
