@import 'src/assets/sass/variables';

.customerDetails {
  min-height: 100%;
}

.customerDetailsContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: $bp-xs) {
    flex-direction: column;
  }
}

.customerDetailsHeader {
  display: grid;
  grid-gap: $grid-base;
  align-items: center;
  grid-template-columns: 1fr auto;
  width: 100%;
  padding-bottom: $grid-small * 2;
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: repeat(auto-fill, minmax($field-width, 1fr));
  }
}

.customerDetailsHeaderButtons {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-base;
  align-items: center;
}
.buttonsIsViewMode {
  grid-gap: 0;
  grid-template-columns: 1fr;
}
