@import 'src/assets/sass/variables';

$phoneActionIconSize: 16px;

.phoneLink {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
}
.phoneValue {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;
}
.phoneActionIcon {
  svg {
    width: $phoneActionIconSize;
    height: $phoneActionIconSize;
  }
}
