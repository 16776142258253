@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

$xtTransferListMinHeight: 250px;
$minListWidth: 130px;
$controlButtonsPaddingTop: 40px;
$paddingRightAddButton: 40px;
$paddingLeftRemoveButton: 42px;
$listContainerPaddingBottom: 15px;
$buttonIconMinWidth: 24px;

.xtTransferListContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: $grid-base;
  @media screen and (max-width: $bp-xs) {
    max-height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 1fr;
  }
}

.listContainer {
  position: relative;
  display: grid;
  grid-auto-rows: auto 1fr;
  min-width: $minListWidth;
  padding-bottom: $listContainerPaddingBottom;
  min-height: $xtTransferListMinHeight;
}

.list {
  overflow-y: auto;
  background-color: $white-color;
  border: $border-grey-primary;
  border-radius: $border-radius-base;
  @extend .xt-scrollbar-style;
}

.transferListLabel {
  margin: $grid-small 0;
}

.controlButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: $controlButtonsPaddingTop;
  padding-bottom: $listContainerPaddingBottom;
  @media screen and (max-width: $bp-xs) {
    padding: 0;
    flex-direction: row;
    align-items: center;
  }

  .controlButton {
    display: grid;
    grid-gap: $grid-medium;
  }
}

.listItemContainer {
  width: 100%;
  overflow: hidden;

  .listItemLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: nowrap;
  }
}

.addIcon {
  min-width: $buttonIconMinWidth;
  @media screen and (max-width: $bp-xs) {
    transform: rotate(90deg);
  }
}

.removeIcon {
  min-width: $buttonIconMinWidth;
  transform: rotate(180deg);
  @media screen and (max-width: $bp-xs) {
    transform: rotate(270deg);
  }
}

.error {
  border-color: $color-orange;
}

.button {
  display: flex;
}

.buttonAdd {
  @extend .button;
  justify-content: center;
  padding-right: $grid-medium;

  @media screen and (max-width: $bp-xs) {
    padding-right: $grid-tiny;
    justify-content: space-between;
  }
}

.buttonRemove {
  @extend .button;
  justify-content: center;
  padding-left: $grid-medium;
  @media screen and (max-width: $bp-xs) {
    padding-left: $grid-tiny;
    justify-content: space-between;
  }
}

.addLabel {
  width: 100%;
  padding: 0 $grid-small;
}

.removeLabel {
  width: 100%;
  padding: 0 $grid-small;
}

.errorMessage {
  position: absolute;
  bottom: 0;
  color: $color-orange;
  word-break: break-word;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  margin: 0 $grid-medium;
}
