$spinner-padding-left: 5px;
$spinner-size: 25px;

.loadingSpinner {
  position: absolute;
  left: $spinner-padding-left;
}

:export {
  spinnerSize: $spinner-size;
}
