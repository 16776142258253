@import 'src/assets/sass/variables';

$xtAutocompleteListHeight: 250px;

.xt-autocomplete-input {
  width: 100%;
  .Mui-disabled {
    color: inherit;
  }
  .MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
    color: $dark-gray;
  }
  .MuiAutocomplete-clearIndicator .MuiSvgIcon-root {
    color: $dark-gray;
  }
}

.xt-autocomplete-list-container.MuiAutocomplete-listbox {
  max-height: $xtAutocompleteListHeight;
}

.xt-autocomplete-container {
  width: 100%;
  overflow: hidden;
}
