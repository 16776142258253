@import 'src/assets/sass/variables';

$svgButtonHeight: 8px;
$svgButtonWidth: 10px;
$buttonWidth: 30px;

.counterButtons {
  display: flex;
  flex-direction: column;

  button {
    min-width: $buttonWidth;
  }
}

.svgIncreaseButton {
  height: $svgButtonHeight;
  width: $svgButtonWidth;
}
.svgDecreaseButton {
  height: $svgButtonHeight;
  width: $svgButtonWidth;
  transform: rotate(180deg);
}

:global {
  .decimalFormInputFilled {
    fieldset legend {
      max-width: 1000px;
      transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    }
    .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}
