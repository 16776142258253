@import 'src/assets/sass/variables';

$widthCreateItemDialog: 500px;

.createItemForm {
  display: flex;
  flex-direction: column;
  padding: $grid-base;
  background-color: $medium-gray;
  // TODO fix this after code merge
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: $widthCreateItemDialog;

  @media screen and (max-width: $bp-sm) {
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
  > *:not(:last-child) {
    margin-bottom: $grid-medium;
  }
}

.createItemFormButtons {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-medium;
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.formField {
  width: 100%;
  display: flex;
  margin-bottom: $grid-medium !important;
}
