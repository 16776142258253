@import 'src/assets/sass/variables';

$authContainerWidth: 500px;
$authContainerBorderRadius: 2px;
$authContainerBoxShadow: 0px 2px 2px rgba(0, 0, 0, 0.3);

.authPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.authPageContent {
  background-color: $medium-gray;
  border-radius: $authContainerBorderRadius;
  box-shadow: $authContainerBoxShadow;
  width: $authContainerWidth;
  padding: $grid-base;

  @media screen and (max-width: $bp-xs) {
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    height: 100%;
  }
}

.login {
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-gap: $grid-base;
}

.authResetPassword {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-gap: $grid-base;
}

.authScreenButtonsLabel {
  white-space: nowrap;
}
.authScreenButtons {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-base;
  align-items: center;
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
  }
}

.authPageLogo {
  max-width: 100%;
  object-fit: contain;
  justify-self: center;
  margin-bottom: $grid-medium;
}

.authFields {
  background-color: white;
}
