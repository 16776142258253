@import 'src/assets/sass/variables';
$minMaxWidth: 160px;
.customerDetailsFields {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $grid-small * 2;
  margin-top: $grid-base;
  @media screen and (max-width: $bp-md) {
    grid-template-columns: repeat(auto-fill, minmax($field-width, 1fr));
  }
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
  }
}
.checkboxActive {
  justife-self: start;
}
