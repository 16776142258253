@import 'src/assets/sass/variables';

$color: rgba(0, 0, 0, 0.5);
$heightMobileMenu: 35px;
$headerHeight: 48px;

.navbarContainer {
  height: $headerHeight;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $grid-medium;
}

.mainBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.headerContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.brandText {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
}

.mobileMenu {
  color: $color;
  border-color: $color;
  border-width: $border-thin;
  border-radius: $border-radius-small;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  height: $heightMobileMenu;
  margin-right: $grid-base / 2;
}

.logout {
  display: flex;
  align-items: center;
}
