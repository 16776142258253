@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

.listContent {
  @extend .xt-list-page-container;

  display: grid;
  grid-template-rows: auto 1fr;
  align-content: start;
  min-height: auto;
  @media screen and (max-width: $bp-xs) {
    grid-template-rows: auto auto;
  }
}

.listControls {
  display: grid;
  grid-auto-rows: auto auto;
  grid-gap: $grid-base;
  margin-bottom: $grid-base;
}

.headerContent {
  padding-bottom: $grid-small * 2;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.filterButton {
  justify-self: start;
}

.contactsListCheckbox {
  max-height: $xtCheckboxMaxHeight;
  justify-self: start;

  > * {
    overflow: initial;
    white-space: initial;
  }
}

.filtersContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $grid-base;
  align-items: center;
}
