@import 'src/assets/sass/variables';

$widthCharacteristicDialog: 500px;

.characteristicDialog {
  display: grid;
  grid-row-gap: $grid-base;
  padding: $grid-base;
  background-color: $medium-gray;
  box-shadow: $xtBoxShadow;
  width: $widthCharacteristicDialog;

  @media screen and (max-width: $bp-xs) {
    width: 100%;
    height: 100%;
    overflow: auto;
    grid-template-rows: repeat(3, auto) 1fr;
  }
}

.characteristicDialogButtons {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-base;
  margin: auto;
  @media (max-width: $bp-xs) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}
