$border-thin: 1px;
$border-thick: 2px;

$white-color: #ffffff;
$sideBar-color: #727274;
$black-color: #333333;
$black-hr: #444444;
$medium-gray: #f7f8fc;
$dark-gray: #9a9a9a;
$light-gray: #e5e5e5; // TODO may we replace it with $medium-gray?
$color-primary: #0868ac;
$color-info: #4eb3d3;

$color-gray-primary: #adaeb0;
$color-gray-normal: #dadada;
$color-orange: #ed6a5a;
$color-grey-blue: #8c96ab;
$color-disabled: rgba(0, 0, 0, 0.38);

$border-grey-primary: solid $border-thin $dark-gray;
$border-black: solid $border-thin $black-color;
$border-grey: solid $border-thin $color-gray-primary; // TODO $border-grey-primary seems the same as $border-grey

$grid-tiny: 5px;
$grid-small: 10px;
$grid-medium: 15px;
$grid-base: 30px;
$grid-giant: 50px;

$border-radius-small: 3px;
$border-radius-base: 4px;
$border-radius-large: 6px;
$border-radius-extreme: 10px;

$font-size-small: 12px;
$font-size-base: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-huge: 20px;
$font-size-colossal: 24px;
$font-size-enormous: 32px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi: 500;
$font-weight-bold: 600;

$font-family: Lato, Arial, sans-serif;

$field-width: 260px;
$field-height: 56px;

$bp-xs: 768px;
$bp-sm: 960px;
$bp-md: 1280px;
$bp-lg: 1920px;

$xtupleNavbarHeight: 56px;
$xtupleFooterHeight: 60px;
$xtupleContentHeight: calc(#{var(--xtViewportHeight, 100vh)} - #{$xtupleNavbarHeight} - #{$xtupleFooterHeight});
$xtupleContentHeightFallback: calc(100vh - #{$xtupleNavbarHeight} - #{$xtupleFooterHeight});

$xtTableHeight: 420px;
$xtDialogWidthSmall: 450px;

$xtBoxShadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

$xtCheckboxMaxHeight: 30px;
