@import 'src/assets/sass/variables';

$tabSelectedBorder: $border-thin solid transparent;
$tabMaxWidth: 300px;
$tabMinWidth: 200px;

.xtTabs {
  width: 100%;

  .xtTabPaperRoot {
    width: 100%;
    box-shadow: none;
    background-color: transparent;

    .xtTabsIndicator {
      display: none;
    }

    .xtTabFlexContainer {
      position: absolute;
      display: flex;
      width: 100%;
    }

    .xtTabRoot {
      cursor: pointer;
      min-width: 0;
      background-color: transparent;
      border: $border-thin solid transparent;
      color: $black-color;
      font-weight: $font-weight-normal;
      text-transform: capitalize;
      font-size: $font-size-large;
      outline: none;
      min-width: $tabMinWidth;
      max-width: $tabMaxWidth;

      &:hover {
        border-bottom: $border-grey;
      }
    }

    .xtTabWrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      display: block;
    }

    .xtTabSelected {
      color: $black-color;
      border: $border-grey;
      border-bottom: $tabSelectedBorder;

      &:hover {
        border-bottom: $tabSelectedBorder;
      }
    }

    .xtTabTextColorPrimary {
      color: $black-color;
      border-radius: 0;

      &.xtTabSelected:not(.xtTabInvalid) {
        color: $black-color;
      }
    }

    .xtTabInvalid {
      color: $color-orange;
    }

    .xtTabDisabled {
      color: $color-disabled;
    }
  }
}

.xtTabContent {
  padding-top: $grid-base;
}
