@import 'src/assets/sass/variables';

.createItems {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.buttonContainer {
  margin-bottom: $grid-base;
  margin-left: auto;
}
