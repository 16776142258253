@import 'src/assets/sass/variables';
$prospectDetailsHeaderPadding: 20px;
$prospectDetailsContentWidth: 400px;

.prospectDetailsHeader {
  display: grid;
  grid-gap: $grid-base;
  align-items: center;
  grid-template-columns: 1fr auto;
  width: 100%;
  padding-bottom: $grid-small + $grid-small;
  @media screen and (max-width: $bp-xs) {
    grid-gap: 0;
    align-items: initial;
    display: flex;
    flex-direction: column;
  }
}

.prospectDetailsMainContentForm {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($field-width, 1fr));
  grid-gap: $grid-base;
  margin-top: $grid-base;
}

.prospectDetailsHeaderButtons {
  display: grid;
  grid-column: 2;
  grid-template-columns: auto auto;
  grid-gap: $grid-base;
  align-items: center;
  @media screen and (max-width: $bp-xs) {
    margin-top: $grid-base;
  }
}

.buttonsIsViewMode {
  grid-gap: 0;
  grid-template-columns: 1fr;
}

.generalTabMainDetailsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: $bp-md) {
    grid-template-columns: repeat(auto-fill, minmax($field-width, 1fr));
  }
  grid-gap: $grid-base;
}
