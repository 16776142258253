@import 'src/assets/sass/variables';

$xtSelectCreatableEndAdornmentIconPadding: 7px;
$xtSelectCreatableEndAdornmentPadding: 9px;
$xtSelectCreatableEndAdornmentIconSize: 1.5em;
$xtSelectCreatableEndAdornmentIconSvgSize: 1.25rem;
$xtSelectCreatableEndAdornmentHeight: 24px;

:global {
  .xtSelectCreatable {
    .MuiAutocomplete-endAdornment {
      display: none;
    }
    .MuiInputBase-root.MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd {
      padding-right: $xtSelectCreatableEndAdornmentPadding;
    }

    &:hover {
      .xtSelectCreatableEndAdornmentIcon {
        visibility: visible;
      }
    }

    .xtSelectCreatableEndAdornmentIcon {
      visibility: hidden;
      padding: $xtSelectCreatableEndAdornmentIconPadding;
      width: $xtSelectCreatableEndAdornmentIconSize;
      height: $xtSelectCreatableEndAdornmentIconSize;
      svg {
        font-size: $xtSelectCreatableEndAdornmentIconSvgSize;
      }
      color: $dark-gray;
      &:focus {
        border-bottom: unset;
      }
    }
  }

  .xtSelectMenu {
    max-width: min-content;
    .MuiMenuItem-root {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: nowrap;
    }
  }
}

.xtSelectCreatableEndAdornment {
  display: flex;
  flex-direction: row;
  height: $xtSelectCreatableEndAdornmentHeight;
  align-items: center;
}
