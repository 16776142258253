@import 'src/assets/sass/variables';

$sidebarWidth: 96px;

.sidebar {
  position: sticky;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebarWidth;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: $grid-small * 2;
  background-color: $medium-gray;
  font-weight: $font-weight-semi;

  @media screen and (max-width: $bp-xs) {
    grid-gap: $grid-tiny;
  }
}

.sidebarOptions {
  margin: 0;
  padding: 0 $grid-small;
  display: grid;
  justify-items: center;
  grid-auto-rows: min-content;
  grid-gap: $grid-small * 2;
  li {
    display: block;
  }

  @media screen and (max-width: $bp-xs) {
    grid-gap: $grid-small;
  }
}
