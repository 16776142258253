@import 'src/assets/sass/variables';

$taskColumnWidth: 320px;
$taskColumnDropAreaMinHeight: 40px;
$tasksMinWidth: 200px;

.tasksBoard {
  height: $xtupleContentHeight;
  display: flex;
  flex-direction: column;
  padding-top: $grid-base;
}

.tasksBoardHeader {
  padding-bottom: $grid-small * 2;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  grid-gap: $grid-base;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: auto auto;
    justify-content: space-between;

    .tasksBoardAddNewButton {
      grid-column: 2;
    }
  }
}

.tasksBoardCheckboxesFilter {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $grid-small;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: repeat(auto-fill, minmax($tasksMinWidth, max-content));
  }
  .taskListCheckbox {
    max-height: $xtCheckboxMaxHeight;
    justify-self: start;

    > * {
      overflow: initial;
      white-space: initial;
    }
  }
}

.tasksBoardCheckboxFilter {
  max-height: $xtCheckboxMaxHeight;
  justify-self: start;

  > * {
    overflow: initial;
    white-space: initial;
  }
}

.tasksBoardAddNewButton {
  grid-column: 4;
}

:global {
  .xt-tasks-kanban-board {
    .xt-scrollable-column {
      width: $taskColumnWidth;
    }
    .xt-kanban-board-section {
      width: $taskColumnWidth;
    }
    .smooth-dnd-container.vertical {
      min-height: $taskColumnDropAreaMinHeight;
      > .smooth-dnd-draggable-wrapper {
        overflow: visible;
      }
    }
  }
  .xt-tasks-kanban-board-mobile {
    .xt-scrollable-column {
      @media screen and (max-width: $bp-xs) {
        width: calc(100% - #{$grid-medium});
      }
    }
  }
}
