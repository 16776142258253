@import 'src/assets/sass/variables';

.fileUpload {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $grid-medium;
}

.browseButton {
  justify-self: end;
}
