@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

$xtCommentHeight: 150px;
$xtCommentMaxWidth: 650px;
$xtCommentMaxWidthMobile: 250px;
$xtCommentHeaderPadding: 10px 8px 10px 20px;
$xtCommentHeaderPaddingMobile: 0 8px 0 20px;
$xtCommentControlsWidth: 310px;
$xtCommentControlsPadding: 14px 20px 14px 20px;

.xtComment {
  width: 100%;
  max-width: $xtCommentMaxWidth;
  display: grid;
  grid-template-rows: auto 1fr;

  @media screen and (max-width: $bp-xs) {
    width: $xtCommentMaxWidthMobile;
  }
}

.xtCommentHeader {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-base;
  align-items: center;
  background-color: $dark-gray;
  padding: $xtCommentHeaderPadding;
  justify-content: space-between;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    padding: $xtCommentHeaderPaddingMobile;
  }
}

.xtCommentUserName {
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  margin: 0;
  color: $white-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.xtCommentDate {
  font-size: $font-size-base;
  align-items: flex-end;
  color: $white-color;
}

.xtCommentContent {
  border: $border-grey-primary;
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: $white-color;
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}

.xtCommentTextarea {
  overflow: hidden;
  width: 100%;
  resize: none;
  border: none;
  border-radius: 0;
  padding: $grid-small $grid-small * 2;
  background-color: $white-color;
  font-family: $font-family;
  font-size: $font-size-medium;
  &:focus {
    outline: none;
  }
}

.xtCommentText {
  word-break: break-word;
  padding: $grid-small $grid-small * 2;
  white-space: break-spaces;
  width: 100%;

  &Placeholder {
    color: $color-disabled;
  }
}

.xtCommentControls {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row-gap: $grid-small;
  width: $xtCommentControlsWidth;
  padding: $xtCommentControlsPadding;

  @media screen and (max-width: $bp-xs) {
    width: 100%;
  }
}

.xtCommentButtonsSection {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-small;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
  }
}
