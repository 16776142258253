.xt-grid-table-button-cell {
  .xt-table-cell-actions {
    opacity: 0;
  }

  &:hover {
    .xt-table-cell-actions {
      opacity: 1;
    }
  }
}
