@import 'src/assets/sass/variables';

$xtQuickAddButtonHeight: 42px;
$xtQuickAddButtonWidth: 50px;
$xtQuickAddButtonRadius: 5px;
$xtQuickAddButtonPadding: 8px 12px;
$xtQuickAddButtonIconSize: 25px;

.xtQuickAddButton {
  cursor: pointer;
  position: relative;
  width: $xtQuickAddButtonWidth;
  height: $xtQuickAddButtonHeight;
  border-radius: $xtQuickAddButtonRadius;
  background-color: $color-primary;
  padding: $xtQuickAddButtonPadding;
  border: none;
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: $dark-gray;
    cursor: not-allowed;
  }
}

.xtQuickAddButtonIcon {
  width: $xtQuickAddButtonIconSize;
  height: $xtQuickAddButtonIconSize;
  color: $white-color;
}
