@import 'src/assets/sass/variables';

$footerFontSize: 16px;
$xTupleLinkColor: #007bff;
$borderColor: #e7e7e7;
$footerLineHeight: 20px;

.footer {
  line-height: $footerLineHeight;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $grid-medium;
  border-top: 1px solid $borderColor;
  bottom: 0;

  nav > ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: normal;

    @media screen and (max-width: $bp-xs) {
      height: 100%;
      flex-direction: column;
    }

    a {
      text-decoration: none;
      color: $dark-gray;
      display: block;
      margin-bottom: 3px;
      font-size: $footerFontSize;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .copyright {
    padding: 10px 15px;
    margin: 10px 3px;
    line-height: 20px;
    font-size: $footerFontSize;
    color: $dark-gray;
    a {
      text-decoration: none;
      color: $xTupleLinkColor;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  hr {
    border-color: $medium-gray;
  }
}

.footer:not(.footer-big) {
  nav > ul {
    font-size: $font-size-base;
    li {
      margin-left: 20px;
      float: left;
    }
    a {
      padding: 10px 0px;
      margin: 10px 10px 10px 0px;
    }
  }
}
