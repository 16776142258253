@import 'src/assets/sass/variables';

$checkboxMinWidth: 160px;

.accountDetailsMainContainer {
  padding-top: $grid-base;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row-gap: $grid-base;
}

.accountDetailsHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: $grid-base;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}

.accountDetailsFormContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($field-width, 1fr));
  grid-gap: $grid-base;
  align-items: center;
  grid-auto-flow: row;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
  }
}

.accountDetailsCheckboxGroup {
  width: 100%;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax($checkboxMinWidth, 1fr));
  grid-column-gap: $grid-base;
  grid-row-gap: $grid-small;
  text-transform: capitalize;
}

.controlButtonsBlock {
  display: grid;
  grid-column-gap: $grid-base;
  grid-template-columns: repeat(2, auto);
}

.controlButtonsBlockViewMode {
  display: flex;
  justify-content: flex-end;
}

.accountDetailsCheckboxActive {
  justify-self: start;
  text-transform: capitalize;
}

.accountDetailsRadioGroup {
  width: 100%;
  display: grid !important;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: $grid-base;
  text-transform: capitalize;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
  }
}

.accountDetailsHeaderSection {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: $grid-base;
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
  }
}

.accountDetailsTabs {
  margin-top: $grid-base;
}
