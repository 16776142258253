@import 'src/assets/sass/variables';
$minMaxWidth: 160px;
$marginContactField: -$grid-base - $grid-small * 2 - 4px;
$maxWidthLeftSide: $field-width * 2 + $grid-medium;
$maxWidthRightSide: $field-width * 3 + $grid-base * 2;
$gridGapRightSide: 67px;
$paddingBottomCheckboxActive: 12px;

.generalFormFields {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  @media screen and (min-width: $bp-md) {
    grid-template-columns: repeat(auto-fit, minmax($maxWidthLeftSide, 1fr));
  }
  grid-gap: $grid-base;
  .leftSide {
    display: grid;
    align-items: center;
    grid-gap: $grid-base;
    grid-template-columns: repeat(auto-fill, minmax($field-width, 1fr));

    @media screen and (max-width: $bp-xs) {
      grid-template-columns: 1fr;
    }
  }
  .rightSide {
    display: grid;
    grid-gap: $gridGapRightSide;
    grid-template-columns: 1fr;

    @media screen and (min-width: $bp-md) {
      max-width: $maxWidthRightSide;
    }
    .contactSection {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: $grid-base;
      width: 100%;
    }

    .contactField {
      padding: 0;
      margin: 0;
      overflow: initial;
      min-width: $field-width * 2;
      @media screen and (max-width: $bp-sm) {
        min-width: $field-width / 2;
      }
    }
    .contactFilterByAccountCheckbox {
      @media screen and (min-width: $bp-md) {
        margin-top: $marginContactField;
      }
      @media screen and (max-width: $bp-xs) {
        margin-right: $grid-base * 2 + $grid-small;
      }
    }
    .dateFields {
      display: grid;
      grid-gap: $grid-base;
      grid-template-columns: repeat(auto-fill, minmax($field-width, 1fr));

      @media screen and (min-width: $bp-md) {
        grid-template-columns: repeat(auto-fill, minmax($field-width, $field-width));
      }

      @media screen and (max-width: $bp-xs) {
        grid-template-columns: 1fr;
      }
    }
  }
}
.checkboxActive {
  justify-self: start;
  padding-bottom: $paddingBottomCheckboxActive;
}
