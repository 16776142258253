@import 'src/assets/sass/variables';

$patterValidatorPopupMaxWidth: 250px;

.patterValidatorPopup {
  padding: $grid-small;
  font-size: $font-size-small;
  display: grid;
  grid-gap: $grid-tiny;
  max-width: $patterValidatorPopupMaxWidth;

  h4 {
    margin: 0;
  }
}

.patterValidatorPopupContent {
  display: grid;
  grid-gap: $grid-tiny;
}

.patterValidatorPopupExample {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
}
