@import 'src/assets/sass/variables';

$opportunityColumnWidth: 320px;
$opportunityColumnDropAreaMinHeight: 60px;
$opportunitiesMinWidth: 200px;

.opportunitiesBoard {
  height: $xtupleContentHeight;
  display: flex;
  flex-direction: column;
  padding-top: $grid-base;
}

.opportunitiesBoardHeader {
  padding-bottom: $grid-small * 2;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  grid-gap: $grid-base;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: auto auto;
    justify-content: space-between;

    .opportunitiesBoardAddNewButton {
      grid-column: 2;
    }
  }
}

.opportunitiesBoardCheckboxesFilter {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $grid-small;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: repeat(auto-fill, minmax($opportunitiesMinWidth, max-content));
  }
  .taskListCheckbox {
    max-height: $xtCheckboxMaxHeight;
    justify-self: start;

    > * {
      overflow: initial;
      white-space: initial;
    }
  }
}

.opportunitiesBoardCheckboxFilter {
  max-height: $xtCheckboxMaxHeight;
  justify-self: start;

  > * {
    overflow: initial;
    white-space: initial;
  }
}

.opportunitiesBoardAddNewButton {
  grid-column: 4;
}

:global {
  .xt-opportunities-kanban-board {
    .xt-scrollable-column {
      width: $opportunityColumnWidth;
    }
    .xt-kanban-board-section {
      width: $opportunityColumnWidth;
    }
    .smooth-dnd-container.vertical {
      min-height: $opportunityColumnDropAreaMinHeight;
    }
  }
  .xt-opportunities-kanban-board-mobile {
    .xt-scrollable-column {
      @media screen and (max-width: $bp-xs) {
        width: calc(100% - #{$grid-medium});
      }
    }
  }
}
