@import 'src/assets/sass/variables';

.cardList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: $font-size-large;
}

.cardContentContainer {
  display: grid;
  width: 100%;
  grid-row-gap: $grid-medium;
}

.label {
  color: $sideBar-color;
  font-size: $font-size-small;
}

.value {
  font-size: $font-size-small;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;
}

.line {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.loadMoreButton {
  margin-top: $grid-base;
}

.tableCellAction {
  > * {
    display: flex;
    flex-flow: wrap;
  }

  margin: $grid-small 0 0 -$grid-small;
}

:global {
  .xt-list-card {
    background-color: $white-color;
    border: none;

    &.selected {
      background-color: $color-gray-normal;
    }
  }
}
