$icon-size: 23px;

.svgIcon {
  height: $icon-size;
  width: $icon-size;
}

.svgIconHidden {
  display: none;
}
