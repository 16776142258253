@import 'src/assets/sass/variables';

.xtConfirmationDialog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $grid-small * 2 $grid-base;
  background-color: $medium-gray;
  box-shadow: $xtBoxShadow;
}

.xtConfirmationDialogTitle {
  font-size: $font-size-huge;
  margin-bottom: $grid-base;
}

.xtConfirmationDialogButtons {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-giant;
  margin-top: $grid-base;
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
    justify-items: initial;
    grid-gap: $grid-base;
  }
}
