@import 'src/assets/sass/variables';
@import '../menu/menu.module';

$profileCardMaxWidth: 300px;

.username {
  font-weight: $font-weight-bold;
  font-size: $font-size-medium;
}
.email {
  font-weight: $font-weight-semi;
  font-size: $font-size-base;
}
.logout {
  padding: $grid-tiny;
  font-size: $font-size-medium;
}
.personalProfileDialog {
  max-width: $profileCardMaxWidth;
}
.profileCard {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: $grid-tiny;
  padding: $grid-tiny;
  overflow: hidden;
  .username,
  .email {
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    text-overflow: ellipsis;
  }
}

.paper {
  margin: $grid-tiny 0;
  .menuList {
    padding: 0;
  }
}
