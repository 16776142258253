@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

$iconSize: 32px;

.listContent {
  @extend .xt-list-page-container;

  display: grid;
  grid-template-rows: auto 1fr;
  align-content: start;
  min-height: auto;
  @media screen and (max-width: $bp-xs) {
    grid-template-rows: auto auto;
  }
}

.listHeader {
  padding-bottom: $grid-small * 2;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.listControls {
  display: grid;
  grid-auto-rows: auto auto;
  grid-gap: $grid-base;
  margin-bottom: $grid-base;
}

.filterButton {
  justify-self: start;
}

.opportunitiesListCheckbox {
  max-height: $xtCheckboxMaxHeight;
  justify-self: start;

  > * {
    overflow: initial;
    white-space: initial;
  }
}

.filtersContainer {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: $grid-base;
  align-items: center;
}

.opportunitiesListLinksGroup {
  display: grid;
  grid-template-columns: auto auto;
  justify-self: end;
}

.opportunitiesListLink {
  text-decoration: none;
  color: $sideBar-color;

  &.opportunitiesListLinkActive {
    color: $black-color;
  }
  &:hover {
    color: $black-color;
  }
  &:focus {
    color: $black-color;
  }
}

.opportunitiesListLinkIcon {
  width: $iconSize;
  height: $iconSize;
}
