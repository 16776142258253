@import 'src/assets/sass/variables';

$documentsDialogWidth: 900px;

.headerContent {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: $grid-medium;
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
  }
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $grid-base;
  align-items: center;
}

.documentsDialog {
  display: grid;
  grid-row-gap: $grid-base;
  grid-template-rows: auto 1fr;
  padding: $grid-base;
  background-color: $medium-gray;
  box-shadow: $xtBoxShadow;
  width: $documentsDialogWidth;
  @media screen and (max-width: $bp-sm) {
    width: 100%;
    height: 100%;
    overflow: auto;
    align-content: start;
    grid-template-rows: auto 1fr 1fr;
  }
}
