@import 'src/assets/sass/variables';

$widthaccountDialog: 100%;

.accountDialogContent {
  width: $widthaccountDialog;
  height: 100%;
  background-color: $medium-gray;
  padding: $grid-base;
  overflow-y: auto;
  @media screen and (max-width: $bp-md) {
    width: 100%;
  }
}

.accountDialogFormHeader {
  display: grid;
  grid-gap: $grid-base;
  align-items: center;
  grid-template-columns: 1fr auto;
  width: 100%;
  padding-bottom: $grid-small + $grid-small;
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: repeat(auto-fill, minmax($field-width, 1fr));
    height: auto;
  }
}
.containerHeaderButtons {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-base;
  align-items: center;
}

.accountForm {
  margin: $grid-base 0 0 0;
  display: grid;
}

.allowText {
  margin-left: 0 !important;
  width: 100%;
}
.radioGroup {
  flex-direction: row !important;
  margin-bottom: $grid-small;
  > * {
    height: $field-height;
    @media screen and (max-width: $bp-xs) {
      height: initial;
    }
  }
}
.helperText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $font-size-small;
}
.formGroup {
  display: flex;

  .formField {
    width: 100%;
    display: flex;
    margin-bottom: $grid-base;
    margin-right: $grid-base;
    &:last-child {
      margin-right: 0;
    }
    @media screen and (max-width: $bp-md) {
      margin-bottom: $grid-base;
    }
  }
  @media screen and (max-width: $bp-md) {
    flex-direction: column;
  }
}
