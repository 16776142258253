@import 'src/assets/sass/variables';

.columnFooter {
  display: flex;
  padding: $grid-medium $grid-small;
  justify-content: center;
  align-items: center;
  color: $white-color;
  font-weight: $font-weight-semi;
  font-size: $font-size-large;
  background-color: $color-gray-primary;
  border-radius: $border-radius-base;
}

.columnFooterText {
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
}
