@import 'src/assets/sass/variables';

.card {
  background-color: $white-color;
  display: grid;
  grid-gap: $grid-tiny;
  padding: $grid-small;
  width: 100%;
  border-radius: $border-radius-base;
  cursor: pointer;

  outline-color: $color-gray-primary;
}

.cardRow {
  display: grid;
  grid-gap: $grid-tiny;
  grid-template-columns: auto 1fr;
}

.cardLabel {
  font-weight: $font-weight-bold;
}

.cardValue {
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
}
