@import 'src/assets/sass/variables';

.documentContent {
  display: grid;
  padding: $grid-base 0;
  grid-template-rows: auto auto;
  grid-gap: $grid-base;
}

.formContent {
  display: grid;
  grid-gap: $grid-base;
  grid-template-columns: repeat(auto-fill, minmax($field-width, 1fr));
  grid-auto-flow: row;
}

.documentsHeader {
  display: grid;
  grid-gap: $grid-base;
  align-items: center;
  grid-template-columns: 1fr auto;
  padding-bottom: $grid-small * 2;

  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
    grid-gap: $grid-small;
  }
}

.headerName {
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.documentsHeaderButtons {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-base;
  align-items: center;
}
