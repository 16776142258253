@import 'assets/sass/variables';

$navigationListWidth: 250px;
$navigationListHeight: 200px;
$buttonToolbarHeight: 44px;
$mobileButtonFontSize: 10px;

.setupWizard {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: $grid-base;
  display: flex;
  flex-direction: column;
  background-color: $medium-gray;
  @media screen and (max-width: $bp-sm) {
    overflow-y: auto;
  }
}
.setupWizardContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: $grid-base 0 0 $grid-base;
  @media screen and (max-width: $bp-sm) {
    padding: $grid-base 0 0 0;
  }
}
.setupWizardContainer {
  height: 100%;
  display: flex;
  padding-bottom: $grid-base;
  @media screen and (max-width: $bp-sm) {
    flex-direction: column;
  }
}
.navigationListContainer {
  height: 100%;
  width: $navigationListWidth;
  border: solid 1px $color-disabled;
  @media screen and (max-width: $bp-sm) {
    width: 100%;
    max-height: $navigationListHeight;
  }
}
.buttonToolbar {
  height: $buttonToolbarHeight;
  display: flex;
  align-items: center;
}
.sectionDivider {
  margin-bottom: $grid-base !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: $color-primary;
  color: $white-color;
}
.buttonContainer {
  display: flex;
  margin-left: auto;
  button {
    margin-right: $grid-small;
    &:last-child {
      margin-right: unset;
    }
    @media screen and (max-width: $bp-xs) {
      padding: 0;
      span {
        font-size: $mobileButtonFontSize !important;
      }
    }
  }
}
.disabledListItem {
  background-color: $color-gray-primary !important;
}
.stepListItem span {
  font-size: 14px;
}
.goBackButton {
  @media screen and (max-width: $bp-xs) {
    display: none;
  }
}
