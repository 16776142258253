.formControlLabelRoot {
  width: fit-content;
}

.radioRoot {
  > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
