@import 'src/assets/sass/variables';

$xtResponsiveButtonHeight: 42px;
$xtResponsiveButtonWidth: 50px;
$xtResponsiveButtonBorderRadius: 5px;

.xtResponsiveButton {
  width: $xtResponsiveButtonWidth;
  height: $xtResponsiveButtonHeight;
  padding: 0;
  margin: 0;
  border-radius: $xtResponsiveButtonBorderRadius;
  span {
    display: none;
  }
}
