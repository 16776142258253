@import 'src/assets/sass/variables';

$link-color: #0074cc;
$link-color-hover: #1198ff;

.emailLink {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;
  max-width: 100%;

  a {
    color: $link-color;

    &:hover {
      color: $link-color-hover;
    }
  }
}
