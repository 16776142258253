@import 'src/assets/sass/variables';

.tasksCalendar {
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;
  grid-gap: $grid-base;
}

.calendarListCheckbox {
  max-height: $xtCheckboxMaxHeight;
  justify-self: start;

  > * {
    overflow: initial;
    white-space: initial;
  }
}

.filtersContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $grid-base;
  align-items: center;
}
