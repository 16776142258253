@import 'src/assets/sass/variables';

$dialogWidth: 400px;
$fieldHeight: 55px;
$fieldWidth: 360px;

.mainContainer {
  width: $dialogWidth;
  background-color: $white-color;
  padding: $grid-base;
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: $bp-xs) {
    width: 100%;
  }
}

.headerText {
  font-weight: $font-weight-normal;
}

.profileDetailsFormContainer {
  display: grid;
  grid-template-rows: repeat(2, auto);
  width: 100%;
  grid-gap: $grid-medium;
}

.controlButtonsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: $grid-base;
}

.formColumn {
  display: grid;
  grid-template-rows: repeat(8, auto);
  grid-gap: $grid-base;
  @media screen and (max-width: $bp-xs) {
    grid-template-columns: 1fr;
  }
}

.logoutButton {
  margin-top: $grid-base;
}

.passwordContainer {
  display: grid;
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: $grid-base;
}
