@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

$presetContentWidth: 310px;

.filterContent {
  display: grid;
  grid-gap: $grid-base;
  grid-template-columns: max-content auto;
  align-items: center;
}

.presetContent {
  display: grid;
  grid-gap: $grid-small;
  grid-template-columns: max-content auto;
  align-items: center;
  width: $presetContentWidth;
}
