@import 'src/assets/sass/variables';

$taskCalendarItemDetailsBoxShadow: 1px 1px 4px 0px $black-color;
$taskCalendarItemDetailsWidth: 250px;
$taskCalendarItemOffset: -10px;

.taskCalendarItem {
  position: relative;
  z-index: 1;
  &:hover {
    .taskCalendarItemDetails {
      display: block;
      width: $taskCalendarItemDetailsWidth;
      height: auto;
    }
  }
}

.taskCalendarItemDetails {
  display: none;
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  left: $taskCalendarItemOffset;
  background-color: $white-color;
  border-radius: $border-radius-base;
  color: $black-color;
  box-shadow: $taskCalendarItemDetailsBoxShadow;
  padding: $grid-small;
}
.taskCalendarItemDetailsRightEdge {
  left: unset;
  right: $taskCalendarItemOffset;
}

.taskCalendarItemLabel {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

.taskCalendarItemValue {
  margin: 0;
}

.taskCalendarItemAssignees {
  display: flex;
  flex-direction: column;
  padding-left: $grid-small;
}

.tasksCalendarItemBlock {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $grid-small;
}
