@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

.noLandscapeContainer {
  display: flex;
  height: 100%;
  width: 100%;

  align-items: center;
  flex-direction: column;
  background-color: $medium-gray;
  justify-content: center;

  .noLandscapeText {
    padding-top: $xtContentPaddingLarge;

    p {
      color: $dark-gray;
      text-align: center;
    }
  }
}
