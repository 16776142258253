@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

$filter-width: 450px;
$button-margin: 10px;
$closeIconSize: 16px;

.filtersFormContent {
  padding: $grid-giant $grid-giant $grid-base $grid-base;
  background-color: white;
  width: $filter-width;
  float: right;
  box-shadow: $xtBoxShadow;
  position: relative;
  height: 100%;
  overflow: auto;

  .closeIcon {
    position: absolute;
    top: $grid-small;
    right: $grid-small;
  }

  @media (max-width: $bp-xs) {
    width: 100%;
    padding-left: $grid-base;
  }

  @extend .xt-scrollbar-style;
}
.filtersContent {
  margin-top: $grid-small * 2;
  display: grid;
  grid-row-gap: $grid-base;
  .muiRadioRoot {
    color: $color-primary;
    &.muiRadioChecked {
      color: $color-primary;
    }
  }
}

.buttonContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $grid-base;

  button {
    white-space: nowrap;
  }

  @media (max-width: $bp-md) {
    display: grid;
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
  }
}

.closeIconSize {
  width: $closeIconSize;
  height: $closeIconSize;
}

.filterCheckbox {
  overflow: hidden;
  justify-self: start;
}

.preSetContent {
  display: grid;
  grid-gap: $grid-small;
  grid-template-columns: max-content auto max-content;
  align-items: center;
}

.button {
  background: #fff;
  border: none;

  .icon {
    color: $dark-gray;
  }
  .label {
    display: none;
  }
}
