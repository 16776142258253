@import 'src/assets/sass/variables';

$sidebarLogoSize: 32px;
$sidebarIconSize: 32px;

.sidebar {
  position: relative;
}

.sidebarLogo {
  padding: $grid-medium $grid-base;
}

.sidebarLogoImage {
  width: $sidebarLogoSize;
  height: $sidebarLogoSize;
  object-fit: contain;
}

.sidebarContent {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.sidebarOption {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
}

.sidebarOptionLink {
  text-decoration: none;
  color: $sideBar-color;

  &.sidebarOptionActive {
    color: $black-color;
  }
  &:hover {
    color: $black-color;
  }
  &:focus {
    color: $black-color;
  }
}

.sidebarOptionLabel {
  margin: 0;
  text-align: center;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
}

.sidebar .sidebarOptionIcon {
  width: $sidebarIconSize;
  height: $sidebarIconSize;
}

.profileButton {
  align-self: end;
  padding: $grid-small;
  visibility: hidden;
  @media screen and (max-width: $bp-sm) {
    visibility: visible;
  }
}
