@import 'src/assets/sass/variables';

$maxWidthPhoneCallDialog: 600px;
$maxWidthPhoneMessageDialog: 500px;
$minButtonWidth: 170px;

.phoneDialog {
  display: grid;
  grid-row-gap: $grid-base;
  padding: $grid-base;
  background-color: $medium-gray;
  box-shadow: $xtBoxShadow;
  @media screen and (max-width: $bp-xs) {
    width: 100%;
    height: 100%;
    padding: $grid-medium;
    overflow: auto;
    align-content: flex-start;
  }
}

.phoneCallDialog {
  @extend .phoneDialog;
  width: $maxWidthPhoneCallDialog;
  @media screen and (max-width: $bp-xs) {
    width: 100%;
  }
}

.phoneTwilioDialog {
  @extend .phoneDialog;
  width: $xtDialogWidthSmall;
  @media screen and (max-width: $bp-xs) {
    width: 100%;
  }
}
.phoneMessageDialog {
  @extend .phoneDialog;
  width: $maxWidthPhoneMessageDialog;
  @media screen and (max-width: $bp-xs) {
    width: 100%;
    height: 100%;
    overflow: auto;
    grid-template-rows: repeat(3, auto) 1fr;
  }
}

.phoneButtons {
  display: grid;
  grid-gap: $grid-base;
  margin: auto;
  white-space: nowrap;
  @media (max-width: $bp-xs) {
    grid-template-columns: repeat(auto-fill, minmax($minButtonWidth, 1fr));
    margin: initial;
  }
}

.phoneActionDialogButtons {
  @extend .phoneButtons;
  grid-template-columns: repeat(3, auto);
  @media (max-width: $bp-xs) {
    grid-template-columns: 1fr 1fr;
    grid-gap: $grid-medium;
    .callViaTwilio {
      grid-column: 1/3;
    }
  }
}

.phoneDialogButtons {
  @extend .phoneButtons;
  grid-template-columns: repeat(2, auto);
  @media (max-width: $bp-xs) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}
.title {
  font-size: $font-size-large;
  margin: 0;
}
