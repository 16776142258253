@import 'assets/sass/variables';
@import '../../setup-wizard.module.scss';

$welcomeMsgContainerHeight: calc(100vh - #{$buttonToolbarHeight} - #{$navigationListHeight} - #{$grid-base * 5});

.welcomeMsgContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: $grid-small;
  background-color: $color-gray-primary !important;
  opacity: 0.5;
  border: solid 1px $color-disabled;

  @media screen and (max-width: $bp-sm) {
    height: $welcomeMsgContainerHeight;
  }
}

.welcomeMsgHeader {
  margin-bottom: $grid-small;
  font-weight: $font-weight-bold;
}
.boldText {
  font-weight: $font-weight-bold;
}
.disabledBullet {
  list-style-type: none;
}
.listContainer {
  padding-bottom: 0;
  margin-bottom: 0;
}
.listContainer > li {
  margin-bottom: $grid-small;
  &:last-child {
    margin-bottom: 0;
  }
}
