@import 'src/assets/sass/variables';

.stepperContainer {
  @media screen and (max-width: $bp-sm) {
    display: none;
  }
}
.progressStepper {
  padding: 0 0 $grid-base 0 !important;
}
.stepperContainer {
  width: 100%;
}
