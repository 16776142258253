@import 'src/assets/sass/variables';

$xtEditableCellOutlineWidth: 1px;
$xtEditableCellPadding: $grid-small * 1.5;
$xtEditableCellInputHeight: 25px;
$xtEditableCellPaddingBottom: 2px; // used to smoothly switch between editable and default cell types

.xt-table-editable-cell-input-container {
  display: flex;
  align-items: center;
}

.xt-table-editable-cell-input {
  border: none;
  width: 100%;
  padding: 0 0 0 $xtEditableCellPadding;
  // The prefixed rule is moved upper than unprefixed which makes it redundant.
  -webkit-padding-after: $xtEditableCellPaddingBottom !important;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  letter-spacing: inherit;
  background-color: transparent;
  height: $xtEditableCellInputHeight;

  &:focus-visible {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.xt-table-editable-cell-default {
  height: $xtEditableCellInputHeight;
  &:focus {
    outline: none;
  }
}
.xt-table-editable-cell {
  height: 100%;

  &.xt-table-cell.MuiTableCell-root {
    padding: 0;
  }

  &:focus-within {
    outline: $xtEditableCellOutlineWidth solid $color-primary;
    outline-offset: -$xtEditableCellOutlineWidth;
  }

  &:focus-visible {
    outline: none;
  }

  div {
    height: 100%;
  }
}
.xt-table-editable-cell-picker.MuiFormField.MuiFormFieldPicker {
  &:focus-visible,
  *:focus-visible {
    outline: none;
  }

  fieldset,
  .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
  }

  input {
    padding-left: $xtEditableCellPadding;
  }
  .MuiInputBase-root {
    font: inherit;
  }
}
.xt-table-editable-cell-select {
  .MuiInputBase-root.MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd {
    padding: 0;
  }

  &:focus-visible,
  *:focus-visible {
    outline: none;
  }
  fieldset,
  .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    padding: 0 $grid-small * 2 0 $xtEditableCellPadding;
    height: $xtEditableCellInputHeight;
  }
  .MuiInputBase-root {
    font: inherit;
    height: 100%;
  }
}
