@import 'src/assets/sass/variables';

.saveDialog {
  display: grid;
  grid-gap: $grid-medium;
  padding: $grid-base;
  background-color: $medium-gray;
  box-shadow: $xtBoxShadow;
  width: 500px;
  align-content: start;
  grid-template-columns: auto max-content;

  @media screen and (max-width: $bp-xs) {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  button {
    width: 126px;
    margin-left: $grid-medium;
  }
}
